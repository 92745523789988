/* eslint-disable */

// import external dependencies
import 'jquery';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import map from './routes/map';
import animation from './routes/animation';

import UIkit from 'uikit';
// import Icons from 'uikit/dist/js/uikit-icons';
import Icons from './icons/uikit-icons-denalt.min.js';

UIkit.use(Icons);


/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  map,
  animation,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});
function toggleBurger(action){
  var class_active = 'is-active';
  if(action == 'hide'){
    $(".vy_mobile_menu").removeClass(class_active);
  }else if(action == 'show'){
    $(".vy_mobile_menu").addClass(class_active);
  }else{
    $(".vy_mobile_menu").toggleClass(class_active);
  }

}
$(function () {
  UIkit.util.on('#offcanvas_mobile', 'show', function () {
    toggleBurger('show');
  });
  UIkit.util.on('#offcanvas_mobile', 'hide', function () {
    toggleBurger('hide');
  });
  UIkit.util.on('#dropdown_mobile', 'show', function () {
    if($('#dropdown_mobile').hasClass('uk-active')){
      toggleBurger('show');
    }
  });
  UIkit.util.on('#dropdown_mobile', 'hide', function () {
    if(!$('#dropdown_mobile').hasClass('uk-active')){
      toggleBurger('hide');
    }
  });
});
// Load Events
jQuery(document).ready(() => routes.loadEvents());

