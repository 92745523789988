/* eslint-disable */


// Import Highway
import Highway from '@dogstudio/highway';

 // Import Transitions
import Fade from '../util/transitions/fade';

// Import Renderers
import DefaultRenderer from '../util/renderer/default-renderer';
import UIkit from 'uikit';
import Map from '../routes/map';

import '../routes/ajax';
import { divIcon } from 'leaflet';

$('.uk-slider-nav a').attr('target','_self');



export default {
  init() {
    Map.init();
    if ( ! String.prototype.getDecimals ) {
      String.prototype.getDecimals = function() {
        var num = this,
          match = ('' + num).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
        if ( ! match ) {
          return 0;
        }
        return Math.max( 0, ( match[1] ? match[1].length : 0 ) - ( match[2] ? +match[2] : 0 ) );
      }
    }


    $(document).ready(function() {
      // Iterate through all text nodes in the document
      function wrapSymbolsInTextNodes(node) {
          if (node.nodeType === Node.TEXT_NODE) {
              // Use a regular expression to match ™ and ® symbols
              var text = node.textContent;
              var newText = text.replace(/™/g, '<sup><small>™</small></sup>').replace(/®/g, '<sup><small>®</small></sup>');
              if (newText !== text) {
                  var wrapper = document.createElement('div');
                  wrapper.innerHTML = newText;
                  node.parentNode.replaceChild(wrapper, node);
              }
          } else {
              // Recursively process child nodes
              for (var i = 0; i < node.childNodes.length; i++) {
                  wrapSymbolsInTextNodes(node.childNodes[i]);
              }
          }
      }

      // Start by processing the entire document body
      wrapSymbolsInTextNodes(document.body);
  });

    function getUrlParam(name) {
      var results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
      return (results && results[1]) || undefined;
    }
    if ( $( ".vy_colors" ).length ) {
      var id = getUrlParam('palette_color');
      // console.log(id);
      if (id != undefined && id > "") {
        // $('body').scrollTo('.vy_colors');
        $([document.documentElement, document.body]).animate({
          scrollTop: $(".vy_colors").offset().top
        } , 1500);
      }
    }
    $('.vy_clipboard_icon').on('click',function(event){
      // console.log('click');
      event.preventDefault();
      var textclipboard = $(this).parents('.vy_clipboard').find('.vy_clipboard_code').text();
      navigator.clipboard.writeText(textclipboard).then(function () {
        $(".vy_clipboard_message_success").addClass("uk-active");
        setTimeout(function () { $(".vy_clipboard_message_success").removeClass("uk-active"); }, 3000);
        // $(".vy_clipboard_message_success").addClass("uk-active").delay(5000).removeClass("uk-active");
      }, function () {
        $(".vy_clipboard_message_error").addClass("uk-active");
        setTimeout(function () { $(".vy_clipboard_message_error").removeClass("uk-active"); }, 3000);
      });
    })
    // Quantity "plus" and "minus" buttons
    $( document.body ).on( 'click', '.plus, .minus', function() {

      var $qty        = $( this ).closest( '.quantity' ).find( '.qty'),
        currentVal  = parseFloat( $qty.val() ),
        max         = parseFloat( $qty.attr( 'max' ) ),
        min         = parseFloat( $qty.attr( 'min' ) ),
        step        = $qty.attr( 'step' );
      // Format values
      if ( ! currentVal || currentVal === '' || currentVal === 'NaN' ) currentVal = 0;
      if ( max === '' || max === 'NaN' ) max = '';
      if ( min === '' || min === 'NaN' ) min = 0;
      if ( step === 'any' || step === '' || step === undefined || parseFloat( step ) === 'NaN' ) step = 1;

      // Change the value
      if ( $( this ).is( '.plus' ) ) {
        if ( max && ( currentVal >= max ) ) {
          $qty.val( max );
        } else {
          $qty.val( ( currentVal + parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      } else {
        if ( min && ( currentVal <= min ) ) {
          $qty.val( min );
        } else if ( currentVal > 0 ) {
          $qty.val( ( currentVal - parseFloat( step )).toFixed( step.getDecimals() ) );
        }
      }

      // Trigger change event
      $qty.trigger( 'change' );
    });




    // FUNCTION COOKIE
    function createCookie(name, value, days) {
      var expires;

      if (days) {
          var date = new Date();
          date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
          expires = "; expires=" + date.toGMTString();
      } else {
          expires = "";
      }
      document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
    }

    function readCookie(name) {
      var nameEQ = encodeURIComponent(name) + "=";
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) === ' ')
              c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0)
              return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
      return null;
    }
    // console.log(readCookie("palette_color"));
    // console.log(readCookie("palette_image"));

    function eraseCookie(name) {
      createCookie(name, "", -1);
    }
    // PALETTE COLORS
    if($('.vy_colors').length > 0){
      var palette_cookie = 'palette';
      var palette_cookie_color = 'palette_color';
      var palette_cookie_image = 'palette_image';
      var cookie_time = 365;
      var palette_cookie_value = readCookie(palette_cookie);
      var palette_cookie_color_value = readCookie(palette_cookie_color);
      var palette_cookie_image_value = readCookie(palette_cookie_image);
      $('.vy_colors_choice.is-search').css("display", "none");
      if((palette_cookie_image_value == null || palette_cookie_image_value == 'undefined') && palette_cookie_color_value == null){
        $('.vy_colors_choice.is-rooms').css("display", "none");
        $('.vy_colors_choice.is-palettes').fadeIn(200)
      $(document).on('click', '.vy_colors_choice_room_link', function (e) {
        $('.vy_colors_choice.is-palettes .vy_colors_choice_wrapper').fadeIn(200);
        }).on('keydown', '.vy_colors_choice_room_link', function (e) {
          var keyCode = e.keyCode || e.which;
          if (keyCode==13){
            $('.vy_colors_choice.is-palettes .vy_colors_choice_wrapper').fadeIn(200);
          }
        });
      }

      if(palette_cookie_image_value != null && palette_cookie_image_value != 'undefined'){
        paletteChangeImage($('.vy_colors_choice_room_image_tag[data-src="'+palette_cookie_image_value+'"]').first().parents('.vy_colors_choice_room_link'));
      }
      if(palette_cookie_value != null && palette_cookie_value != 'undefined'){
        paletteChangeColors($('.vy_colors_choice_palettes_item[data-id="'+palette_cookie_value+'"] .vy_colors_choice_palettes_item_link'));
      }
      if(palette_cookie_color_value != null && palette_cookie_color_value != 'undefined'){
        paletteColorChange($('.vy_colors_palette_content_wrapper .vy_colors_palette_item_color[data-code="'+palette_cookie_color_value+'"]').first(),false);
      }
    }
    function paletteOpenRooms(currentElement){
      $('.vy_colors_choice.is-rooms').fadeIn(200).addClass('is-closable');
    }
    function paletteOpenColors(currentElement){
      $('.vy_colors_choice.is-palettes').fadeIn(200).addClass('is-closable');
    }
    function paletteOpenSearch(currentElement){
      $('.vy_colors_choice.is-search').fadeIn(200);
    }
    function paletteChangeImage(currentElement){
      var src = $(currentElement).parents('.vy_colors_choice_room').find('.vy_colors_choice_room_image_tag').attr('src');
      var srcset = $(currentElement).parents('.vy_colors_choice_room').find('.vy_colors_choice_room_image_tag').attr('srcset');
      $('.vy_colors_choice.is-rooms').fadeOut(200);
      $('.vy_colors_room_image').attr('src',src).attr('srcset',srcset).data('src',src).data('srcset',srcset);

      createCookie(palette_cookie_image,src,cookie_time);

    }
    function paletteChangeColors(currentElement){
      var id = $(currentElement).parent().data('id');
      $('.vy_colors_choice.is-palettes').fadeOut(200);
      $('.vy_colors_palette_wrapper').addClass('uk-hidden');
      $('#'+id).removeClass('uk-hidden');
      $('.vy_colors_palette_item_color').css({marginBottom:0});
      $('.vy_colors_palette_dropdown').addClass('uk-hidden');
      $('.vy_colors_palette_item_color.uk-active').removeClass('uk-active')

      createCookie(palette_cookie,id,cookie_time);

    }

    // FUNCTION MUSEUM COLORS//

      // $(document).on('click', '.vy_colors_choice_palettes_item_link', function (e) {
      //   var $palettes = $('.vy_colors_rooms_palettes');
    
      //   if (!$('#mbam-collection').hasClass('uk-hidden')) {
      //     console.log("#mbam-collection 'uk-hidden' class");
      //     $palettes.addClass('uk-light vy_colors_rooms_palettes_museum');
      //   } else {
      //     console.log("#mbam-collection no 'uk-hidden'");
      //     $palettes.removeClass('uk-light vy_colors_rooms_palettes_museum');
      //   }
      // });
      
    // END FUNCTION MUSEUM COLORS //

    function paletteColorChange(currentElement,scroll = true){
      // console.log(currentElement);
      var color = $(currentElement).data('color');
      var code = $(currentElement).data('code');
      $('.vy_colors_palette_dropdown').addClass('uk-hidden');
      $('.vy_colors_palette_item_color').removeClass('uk-active');
      $(currentElement).addClass('uk-active');
      $('.vy_colors_room').css('background', color);

      createCookie(palette_cookie_color,color,cookie_time);

      if(scroll == true && $('.vy_colors_palette').hasClass('uk-first-column')){
        $([document.documentElement, document.body]).animate({
            scrollTop: $(".vy_colors_room").offset().top
        }, 1000);
      }


      var nextElements = $(currentElement).parent('li').nextUntil('.uk-first-column');
      var lastElementRow = $(nextElements).get(-1);
      boxinfo_palette(currentElement,lastElementRow);

      if(!$(currentElement).parents('.vy_colors_choice_search').length){
        var currentUrl = window.location.href;
        var urlPath =  window.location.origin + window.location.pathname;
        var logo = getUrlParam('logo');

        var finalUrl = urlPath + '?palette=' + $(currentElement).parents('.vy_colors_palette_wrapper').attr('id') + '&palette_color=' + code;
        if (logo != undefined && logo > ""){
          // console.log(logo);
          finalUrl += '&logo='+logo;
        }
        $('#modal-iframe-share .vy_clipboard_code').text(finalUrl);
        if(currentUrl != finalUrl){
          history.pushState({}, null, finalUrl);
        }
      }
    }
    function boxinfo_palette(currentElement, lastElementRow) {
      var code = $(currentElement).data('code');
      var color = $(currentElement).data('color');
      var title = $(currentElement).data('uk-tooltip');
      var dropdown = "";

      if(!lastElementRow){
        lastElementRow = $(currentElement).parent('li');
      }
      if($(currentElement).parents('.vy_colors_choice_search').length){
        dropdown = $(currentElement).parents('.vy_colors_choice_search').find('.vy_colors_palette_dropdown');
      }else{
        dropdown = $(currentElement).parents('.vy_colors_palette_content_wrapper').find('.vy_colors_palette_dropdown');
      }
      // $('.vy_colors_palette_dropdown').remove();
      // $(lastElementRow).after('<li class="vy_colors_palette_dropdown uk-width-1-1"><div class="vy_colors_palette_dropdown_wrapper uk-card-body uk-card-small"><h3 class="uk-h5 vy_colors_palette_dropdown_title">'+title+'</h3><div class="vy_extra vy_colors_palette_dropdown_extra">'+code+' - '+color+'</div><div class="vy_colors_palette_dropdown_share"><a target="_self" href="#modal-iframe-share" data-uk-toggle class="uk-button uk-button-small uk-button-link"><span class="uk-icon" data-uk-icon="forward"></span><span>Share this color</span></a></div></div></li>')


      $('.vy_colors_palette_item_color').css({marginBottom:0});
      $(dropdown).removeClass('uk-hidden');
      $(dropdown).find('.vy_colors_palette_dropdown_title').text(title);
      $(dropdown).find('.vy_colors_palette_dropdown_extra').text(code+' - '+color);

      if($('.vy_colors_palette_item_color').hasClass('uk-active')){
        // $(currentElement).css({marginBottom:$(dropdown).height()});
        var currentElement_position = $(currentElement).position();
        var currentElement_height = $(currentElement).height();
        var dropdown_height = $(dropdown).height();
        $(currentElement).css({marginBottom:dropdown_height + 25});
        $(dropdown).css({position:'absolute',left:0,top:currentElement_position.top + currentElement_height + 20});



      $(window).on('load resize', function () {
        $('.vy_colors_palette_item_color').css({marginBottom:0});
        $(currentElement).css({marginBottom:$(dropdown).height()});
        var currentElement_position = $(currentElement).position();
        var currentElement_height = $(currentElement).height();
        var dropdown_height = $(dropdown).height();
        $(currentElement).css({marginBottom:dropdown_height+25});
        $(dropdown).css({position:'absolute',left:0,top:currentElement_position.top + currentElement_height + 20});
      });
    }else{
      $('.vy_colors_palette_dropdown').addClass('uk-hidden');
    }
    }

    function searchColors(searchText){
      var dataTooltip = ""
      var dataCode = ""
      $(".vy_colors_choice_search .vy_colors_palette_item").each(function(){
        dataTooltip = $(this).find(".vy_colors_palette_item_color").attr("data-uk-tooltip").toLowerCase(); //mettre en lowercase
        dataTooltip = dataTooltip.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // retiré tout les accents
        dataCode = $(this).find(".vy_colors_palette_item_color").attr("data-code");
        if(!dataTooltip.includes(searchText) && !dataCode.includes(searchText)){
          $(this).addClass("uk-hidden");
        }else{
          $(this).removeClass("uk-hidden");
        }
      });
    }

    $(window).on('load resize', function () {
      $('.vy_colors_choice.is-rooms').width( $('.vy_colors_room').width());
    });

    $( document ).ready(function() {
      if($('.vy_colors_palette_item_color').hasClass('uk-active')){

        $('.vy_colors_palette_content').animate({
          scrollTop: $('.vy_colors_palette_item_color.uk-active').position().top
          }, 1000);
      }
    });

    $(document).on('click', '.vy_colors_palette_item_color', function (e) {
      e.preventDefault();
      if(!$(this).hasClass('uk-active')){
        paletteColorChange(this);
      }
    }).on('keydown', '.vy_colors_palette_item_color', function (e) {
      if(!$(this).hasClass('uk-active')){
        var keyCode = e.keyCode || e.which;
        if (keyCode==13){
          e.preventDefault();
          paletteColorChange(this);

        }
      }
    });
    $(document).on('click', '.vy_colors_choice_room_link', function (e) {
      e.preventDefault();
        paletteChangeImage(this);
    }).on('keydown', '.vy_colors_choice_room_link', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode==13){
          e.preventDefault();
          paletteChangeImage(this);
        }
    });
    $(document).on('click', '.vy_colors_choice_palettes_item_link', function (e) {
      e.preventDefault();
        paletteChangeColors(this);
    }).on('keydown', '.vy_colors_choice_palettes_item_link', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode==13){
          e.preventDefault();
          paletteChangeColors(this);
        }
    });
    $(document).on('click', '.vy_colors_header_btnroom', function (e) {
      e.preventDefault();
        paletteOpenRooms(this);
    }).on('keydown', '.vy_colors_header_btnroom', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode==13){
          e.preventDefault();
          paletteOpenRooms(this);
        }
    });
    $(document).on('click', '.vy_colors_header_btnpalette', function (e) {
      e.preventDefault();
        paletteOpenColors(this);
    }).on('keydown', '.vy_colors_header_btnpalette', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode==13){
          e.preventDefault();
          paletteOpenColors(this);
        }
    });
    $(document).on('click', '.vy_colors_header_btnsearch', function (e) {
      e.preventDefault();
        paletteOpenSearch(this);
    }).on('keydown', '.vy_colors_header_btnsearch', function (e) {
        var keyCode = e.keyCode || e.which;
        if (keyCode==13){
          e.preventDefault();
          paletteOpenSearch(this);
        }
    });
    $(document).on('keyup', '.vy_colors_choice_searchbar_input', function (e) {
      var searchText= $(this).val().toLowerCase();
      searchText = searchText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      searchColors(searchText);
      $(this).parents('.is-search').find('.vy_colors_palette_dropdown').addClass('uk-hidden');
    });

    $(document).on('click', '.vy_colors_choice_close', function (e) {
      e.preventDefault();
      $(this).parents('.vy_colors_choice').fadeOut(200);
    });

    $(document).on('mouseenter', '.vy_link_cover', function () {
      $(this).parent().find(".uk-button").addClass('uk-active');
      $(this).parent().find(".uk-icon-button").parent().addClass('uk-active');
      $(this).parent().addClass('uk-transition-active uk-active');
    }).on('mouseleave', '.vy_link_cover', function () {
      $(this).parent().find(".uk-button").removeClass('uk-active');
      $(this).parent().find(".uk-icon-button").parent().removeClass('uk-active');
      $(this).parent().removeClass('uk-transition-active uk-active');
    }).on('focus', '.vy_link_cover', function () {
      $(this).parent().find(".uk-button").addClass('uk-active');
      $(this).parent().find(".uk-icon-button").parent().addClass('uk-active');
      $(this).parent().addClass('uk-transition-active uk-active');
    }).on('blur', '.vy_link_cover', function () {
      $(this).parent().find(".uk-button").removeClass('uk-active');
      $(this).parent().find(".uk-icon-button").parent().removeClass('uk-active');
      $(this).parent().removeClass('uk-transition-active uk-active');
    });
    $( ".uk-offcanvas-bar a" ).unbind('click').on( "click",function(){
      //.uk-offcanvas-bar
      UIkit.offcanvas('.uk-offcanvas.uk-open').hide();
    });

    $(function() {
      $(document).on('click', '.vy_navbar_primary_dropdown_close', function () {
        UIkit.drop('.vy_navbar_primary_dropdown').hide(false);
      })


      if ( $( "#vy_header_global_alert" ).length ) {
        var globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
        var global_alert = $('#vy_header_global_alert');

        console.log(globalAlertSeen);
        console.log(global_alert);


        if(globalAlertSeen != true){
          console.log('in');
          global_alert.removeClass('uk-hidden');
        }else{

          UIkit.alert(global_alert).close();
        }
        $('.vy_header_global_alert_close').click(function(){
          if (window.sessionStorage) {
            var dropselvalue = 1;
            sessionStorage.setItem("globalAlertSeen", dropselvalue);
            globalAlertSeen = parseInt(sessionStorage.getItem("globalAlertSeen"));
          }
        });
      }
      if ( $( "#vy_popup" ).length ) {
        var now = new Date()
        var globalPopupSeenStr = sessionStorage.getItem("globalPopupSeen");

        var global_popup = $('#vy_popup');

        if(globalPopupSeenStr){
          var globalPopupSeen = JSON.parse(globalPopupSeenStr);
          var globalPopupSeenDate = new Date(globalPopupSeen.expiry);
          if (now.setDate(now.getDate()) > globalPopupSeenDate.setDate(globalPopupSeenDate.getDate())) {
            sessionStorage.removeItem("globalPopupSeen");
            UIkit.modal(global_popup).show();
          }
        }else{
          UIkit.modal(global_popup).show();
        }

        $('.vy_popup_close').click(function(){
          if (window.sessionStorage) {
            var date = new Date();
            date.setDate(date.getDate() + 7);
            var dropselvalue = 1;
            const item = {
              value: dropselvalue,
              expiry: date,
            }
            sessionStorage.setItem("globalPopupSeen", JSON.stringify(item));
            globalPopupSeen = parseInt(sessionStorage.getItem("globalPopupSeen"));
          }
        });
      }


      UIkit.util.on('.vy_navbar_ecom_search_drop', 'show', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });
      UIkit.util.on('.vy_navbar_ecom_search_drop', 'hide', function () {
        $('.vy_main_content').toggleClass('uk-active');
      });

      if($('.vy_banner_slideshow').length){
        UIkit.util.on('.vy_banner_slideshow > .uk-slideshow', 'itemshow', function (e) {
          if($(e.target).find('.vy_banner_slideshow_content_wrapper').hasClass('uk-light')){
            $('.vy_banner_slideshow_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_slideshow_dotnav').removeClass('uk-light');
          }
        });
      }
      if($('.vy_banner_hero_item').length){
        UIkit.util.on('.vy_banner_hero > .uk-slider', 'itemshow', function (e) {
          if($(e.target).hasClass('uk-light')){
            $('.vy_banner_hero_dotnav').addClass('uk-light');
          }else{
            $('.vy_banner_hero_dotnav').removeClass('uk-light');
          }
        });
      }
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

  },
};

// Get IE or Edge browser version
var version = detectIE();
var using_IE = false;

if (version === false) {
  console.log('IE/Edge');
} else if (version >= 12) {
  console.log('Edge ' + version);
} else {
  console.log('IE ' + version);
  using_IE = true;

  setTimeout(function(){
    $('.home .vy_loading').fadeOut();
  }, 4000);
}
// add details to debug result
console.log (window.navigator.userAgent);

// Call Highway.Core once.
if($('.transition').length != 0  && using_IE == false){
  const H = new Highway.Core({
    transitions: {
      default: Fade
    },
    renderers: {
      name: DefaultRenderer,
      //home: TimeOut
    }
  });
  H.on('NAVIGATE_END', ({ location }) => {
    // Check Anchor
    if (location.anchor) {
      // Get element
      const el = document.querySelector(location.anchor);

      if (el) {
        // Scroll to element
        window.scrollTo(el.offsetLeft, el.offsetTop);

      }
    }
  });
}


function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}
