/* eslint-disable */

import { ready } from 'jquery';
import lottieWeb from 'lottie-web';
import UIkit from 'uikit';

var animation = new Array();

function loadBMAnimation(element,i) {
  var $animation_params = {
    name: 'animation '+i,
    container: element,
    renderer: "svg",
    loop: Boolean($(element).data('loop')),
    autoplay: Boolean($(element).data('autoplay')),
    path: $(element).data('path')
  }
  if ($(element).attr('data-preserveaspectratio')) { $animation_params['rendererSettings'] = { preserveAspectRatio:$(element).data('preserveaspectratio') } }
  animation[i] = lottieWeb.loadAnimation($animation_params);

  var util = UIkit.util;
  var el = util.$(element);
  UIkit.scrollspy(el, { repeat: true });
  util.on(el,'inview', function(){
    animation[i].play();
  });

  if(Boolean($(element).data('repeat'))){
    util.on(el,'outview', function(){
      animation[i].stop();
    });
  }
}

$(function() {
  var element = document.getElementsByClassName("js_animation");

  for (var i = 0; i < element.length; i++) {
    loadBMAnimation(element[i],i);
  };

  $('.vy_boxanimation').each(function(index) {
    $(this).addClass('animate-ready');
  });

});
